<template>
  <section class="columnStatistics">
    <p class="columnStatistics__description">{{ description }}</p>
    <div class="columnStatistics__contentColumns">
      <div class="columnStatistics__loadingChart skeletonS" v-if="isLoading"></div>
      <column-chart
        class="columnStatistics__columChart"
        :min="0"
        :max="100"
        suffix="%"
        height="100%"
        :data="data"
        :library="chartOptions"
        v-else-if="data.length"
      ></column-chart>
      <span v-else>No se han registrado datos en este filtro</span>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["description", "data", "keyData"],
  data() {
    return {
      chartOptions: {
        colors: ["#bd0909"],
      },
    };
  },
  computed: {
    ...mapGetters("Occupation", ["loadingIn"]),
    isLoading() {
      return this.loadingIn(this.keyData);
    },
  },
  beforeDestroy() {
    this.$store.commit(`Occupation/changeLoadingIn`, { name: this.keyData, value: true });
  },
};
</script>

<style lang="scss">
.columnStatistics {
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  &__contentColumns {
    @include Flex(row);
    width: 100%;
    padding: 15px 20px 20px 20px;
    height: calc(100% - 67px);
  }
  &__description {
    padding: 20px;
    font-size: 17px;
    font-weight: 600;
    border-bottom: 2px solid #ececec;
  }
  &__loadingChart {
    height: 400px;
    width: 100%;
    max-width: 100%;
    border-radius: 12px;
  }
}
</style>
